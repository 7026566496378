import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { H2 } from 'components/Typography';

import { createGtmEvent, getImageSrc } from 'utils';
import { DEVICES } from 'constants/subscriptions';

import { imgSmile, imgThumbsUp, imgRocket } from 'assets/img';
import { useIntersectionObserver } from 'hooks';
import { GTM_EVENT_ACTIONS, GTM_EVENT_LABELS } from 'constants/index';
import { Bebefits, Benefit, BenefitImg, BenefitText } from './styles';

const BENEFITS = [
  {
    id: 1,
    img: imgSmile,
  },
  {
    id: 2,
    img: imgRocket,
  },
  {
    id: 3,
    img: imgThumbsUp,
  },
];

const WhatGiveSubscription = () => {
  const ref = React.useRef();
  const onScreen = useIntersectionObserver(ref, { threshold: 0.5 });

  useEffect(() => {
    if (onScreen) {
      createGtmEvent({
        eventLabel: GTM_EVENT_LABELS.whatForSubscription,
        eventAction: GTM_EVENT_ACTIONS.blockVisibility,
      });
    }
  }, [onScreen]);
  return (
    <Fragment ref={ref}>
      <H2>
        <FormattedMessage id="whatGiveSubscription.title" />
      </H2>

      <Bebefits>
        {BENEFITS.map(({ id, img }) => (
          <Benefit key={id}>
            <BenefitImg>
              <img
                {...getImageSrc({
                  url: img,
                  name: id,
                  device: DEVICES.all,
                  resizerParams: {
                    '2x': `&fit=cover&w=28 28w`,
                    '1x': `&fit=cover&w=w=48 48w`,
                    default: `&fit=cover&w=28`,
                  },
                })}
                width="28"
                height="28"
                loading="lazy"
                sizes="(min-width: 640px) 48px, 28px"
                alt={`benefit ${id}`}
              />
            </BenefitImg>

            <BenefitText>
              <FormattedMessage id={`benefit.${id}`} />
            </BenefitText>
          </Benefit>
        ))}
      </Bebefits>
    </Fragment>
  );
};

export default WhatGiveSubscription;
