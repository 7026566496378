import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { getLocaleISOCode, getLocaleUrlPath } from 'utils';
import { useSelectorProps, useSeoMeta } from 'hooks';

import PageHeader from 'components/PageHeader';

import { SCHEMA, PUBLISHER, PAGES } from 'constants/index';

const LandingPageHeader = ({ loadData }) => {
  const dispatch = useDispatch();
  const selectorProps = useSelectorProps();
  const intl = useIntl();
  const { locale } = intl;
  const { location } = useSelectorProps();

  const { metaDescription, metaTitle } = useSeoMeta(PAGES.landingPage);

  const pageSchema = useMemo(
    () =>
      JSON.stringify({
        ...SCHEMA.type.webPage,
        url: `${process.env.APP_DEPLOY_URL}${getLocaleUrlPath(locale)}${
          location.pathname
        }`,
        name: metaTitle,
        description: metaDescription,
        inLanguage: getLocaleISOCode(locale),
        isPartOf: {
          ...SCHEMA.type.webSite,
          publisher: PUBLISHER,
        },
      }),
    [metaDescription, metaTitle, locale, location.pathname]
  );

  const pageScripts = useMemo(
    () => [
      {
        type: 'application/ld+json',
        innerHTML: pageSchema,
      },
    ],
    [pageSchema]
  );

  useEffect(() => {
    loadData(dispatch, selectorProps);
  }, []);

  return (
    <PageHeader pageName={PAGES.landingPage} schemaScripts={pageScripts} />
  );
};

LandingPageHeader.propTypes = {
  loadData: PropTypes.func.isRequired,
};

export default memo(LandingPageHeader);
