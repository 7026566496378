import styled from 'styled-components';

import { mediaQueryMobileLargeMin } from 'styles';

export const Bebefits = styled.div`
  @media ${mediaQueryMobileLargeMin} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    justify-content: flex-start;
  }
`;

export const Benefit = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;

  @media ${mediaQueryMobileLargeMin} {
    flex-direction: column;
    margin-top: 0;
  }

  &:first-child {
    margin-top: 0;
  }
`;

export const BenefitImg = styled.div`
  background: linear-gradient(
    88.99deg,
    rgba(191, 211, 232, 0.3776) 4.97%,
    rgba(216, 208, 230, 0.64) 93.51%
  );
  border-radius: 22px;
  margin-right: 16px;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${mediaQueryMobileLargeMin} {
    width: 112px;
    height: 112px;
    border-radius: 40px;
    margin-bottom: 24px;
  }

  img {
    @media ${mediaQueryMobileLargeMin} {
      width: 48px;
      height: 48px;
    }
  }
`;

export const BenefitText = styled.div`
  display: flex;
  align-items: center;

  @media ${mediaQueryMobileLargeMin} {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }
`;
