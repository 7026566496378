import React, { memo } from 'react';
import PropTypes from 'prop-types';

import * as icons from 'assets/icons';

const spriteFilename = `${process.env.APP_ASSETS_PUBLIC_PATH}sprite.${process.env.APP_VERSION}.svg`;

function Icon({ name, ...props }) {
  const icon = icons[name];

  if (!icon) {
    return null;
  }

  return (
    <svg viewBox={icon.viewBox} {...props}>
      <use xlinkHref={`${spriteFilename}#${icon.id}`} />
    </svg>
  );
}

Icon.propTypes = {
  name: PropTypes.string,
};

Icon.defaultProps = {
  name: '',
};

export default memo(Icon);
