import styled from 'styled-components';

import Icon from 'components/Icon';
import Button from 'components/Button';

import {
  colorWhite,
  colorGraySemiLight,
  colorBlack900,
  colorGray400,
  colorGreen,
  mediaQueryMobileLargeMin,
  fontFamilyPrimary,
  colorPrimary400,
} from 'styles';

export const Periods = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${({ periodsLength }) =>
    `repeat(${periodsLength}, 1fr)`};
  grid-column-gap: 4px;
  margin-top: 24px;

  @media ${mediaQueryMobileLargeMin} {
    grid-column-gap: 16px;
  }
`;

export const PeriodTitle = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 700 12px/16px ${fontFamilyPrimary};
  box-shadow: inset 0px -1px 0px ${colorGraySemiLight};
  background-color: ${colorWhite};
  position: relative;
  top: 2px;
  width: calc(100% - 4px);
  left: 2px;
  border-radius: 6px 6px 0 0;

  @media ${mediaQueryMobileLargeMin} {
    font-size: 16px;
  }
`;

export const PeriodBody = styled.div`
  padding: 24px 0 0;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
`;

export const PeriodPrice = styled.div`
  color: ${colorBlack900};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PeriodPriceValue = styled.strong`
  font-size: 20px;
  line-height: 22px;

  @media ${mediaQueryMobileLargeMin} {
    font-size: 24px;
  }
`;

export const PeriodDicount = styled.div`
  margin-right: 4px;
  color: ${colorGray400};
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: line-through;

  @media ${mediaQueryMobileLargeMin} {
    font-size: 16px;
  }
`;

export const PeriodFooter = styled.div`
  margin: 32px 12px 24px;
  text-align: center;
  margin-right: 4px;
  color: ${colorGray400};
  font-size: 13px;
  line-height: 16px;

  @media ${mediaQueryMobileLargeMin} {
    font-size: 14px;
  }
`;

export const Period = styled.div`
  width: 100%;
  background-color: ${colorWhite};
  box-shadow: inset 0 0 0 2px ${colorGraySemiLight};
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    width: 100%;
    height: 100%;
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s;
    transform: scale(0);
    border-radius: 8px;
  }

  &::before {
    border-bottom: 2px solid
      ${({ activeBorderColor }) => activeBorderColor || colorGreen};
    border-left: 2px solid
      ${({ activeBorderColor }) => activeBorderColor || colorGreen};
    transform-origin: 0 100%;
  }

  &::after {
    border-top: 2px solid
      ${({ activeBorderColor }) => activeBorderColor || colorGreen};
    border-right: 2px solid
      ${({ activeBorderColor }) => activeBorderColor || colorGreen};
    transform-origin: 100% 0;
  }

  ${({ isActive }) =>
    isActive &&
    `
    &::after,
    &::before {
      transform: scale(1);
    }
  `};

  ${({ periodBackgrounds }) =>
    periodBackgrounds?.length > 0
      ? periodBackgrounds.map(
          (bg, index) => `
          &:nth-child(${index + 1}) {
            background: ${bg};
          }
        `
        )
      : ''}

  ${PeriodTitle} {
    color: ${({ activeHeaderColor, isActive }) =>
      activeHeaderColor && isActive ? activeHeaderColor : colorBlack900};
  }

  ${PeriodPriceValue} {
    color: ${({ activeHeaderColor, isActive }) =>
      activeHeaderColor && isActive ? activeHeaderColor : colorBlack900};
  }
`;

export const IconStyled = styled(Icon)`
  margin-right: 5px;
`;

export const SubscribeButton = styled(Button)`
  width: 100%;
  margin: 24px 0;
`;

export const SubscribeButtonDiscount = styled.span`
  font: 12px/16px ${fontFamilyPrimary};
  letter-spacing: 0.03em;
  text-transform: none;
`;

export const ActiveMessage = styled.p`
  margin: 24px 0;
  font-size: 14px;
  line-height: 24px;

  @media ${mediaQueryMobileLargeMin} {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
  }
`;

export const PeriodDiscountPersent = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  font: 12px/16px ${fontFamilyPrimary};
  letter-spacing: 0.03em;
  color: ${colorWhite};
  background-color: ${colorPrimary400};
`;
