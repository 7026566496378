import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { GTM_EVENT_ACTIONS, SUBSCRIPTION_STATES } from 'constants/index';

import { colorBlack900 } from 'styles';

import {
  Periods,
  Period,
  PeriodTitle,
  PeriodBody,
  PeriodPrice,
  PeriodDicount,
  PeriodFooter,
  IconStyled,
  SubscribeButton,
  PeriodPriceValue,
  PeriodDiscountPersent,
  SubscribeButtonDiscount,
  ActiveMessage,
} from './styles';
import { useGADataAttrs } from '../../hooks';

const SubscriptionsPlans = ({
  service,
  tariffs,
  userSubscriptions,
  onChange,
  onSubscribe,
  activePeriodId,
  withDiscountPersent,
  styles,
  periodBackgrounds,
  subscribeButtonVariant,
  activeBorderColor,
  activeHeaderColor,
  isLoadedSubscription,
}) => {
  const { formatMessage } = useIntl();
  const [activePeriod, setActivePeriod] = useState(activePeriodId);

  const selectedPeriod = useMemo(
    () => tariffs.find(({ id }) => id === activePeriod),
    [activePeriod, tariffs]
  );

  const isActiveSubscriptions = useMemo(
    () =>
      userSubscriptions.find(
        ({ status }) =>
          status === SUBSCRIPTION_STATES.active ||
          status === SUBSCRIPTION_STATES.new
      ),
    [userSubscriptions]
  );

  const handleOnClick = id => {
    setActivePeriod(id);
    onChange(id);
  };

  const handleOnSubscribe = useCallback(() => {
    onSubscribe({
      service,
      tariffId: selectedPeriod.id,
      totalPrice: selectedPeriod.price,
    });
  }, [selectedPeriod, service, onSubscribe]);

  const gADataAttrs = useGADataAttrs({
    label: formatMessage(
      {
        id: 'chooseSubscribe.button',
      },
      {
        value: selectedPeriod?.price,
        period: selectedPeriod.title,
      }
    ),
    action: GTM_EVENT_ACTIONS.activateButton,
  });

  return (
    <>
      <Periods periodsLength={tariffs.length} style={styles}>
        {tariffs.map(
          (
            {
              id,
              price,
              monthsNumber,
              pricePerMonth,
              title,
              note,
              durationPeriod,
            },
            index
          ) => {
            const isNotSecondTariff = tariffs.length > 1 && index !== 0;

            return (
              <Period
                key={id}
                $id={id}
                isActive={id === activePeriod}
                onClick={() => handleOnClick(id)}
                style={styles?.Period || {}}
                periodBackgrounds={periodBackgrounds}
                activeBorderColor={activeBorderColor}
                activeHeaderColor={activeHeaderColor}
              >
                <PeriodTitle>
                  {id === activePeriod && (
                    <IconStyled
                      name="check"
                      width="12"
                      height="10"
                      color={activeHeaderColor || colorBlack900}
                    />
                  )}

                  {title}
                </PeriodTitle>

                <PeriodBody>
                  <PeriodPrice>
                    {isNotSecondTariff && (
                      <PeriodDicount>
                        <FormattedMessage
                          id="price"
                          values={{
                            value: tariffs[0].price,
                          }}
                        />
                      </PeriodDicount>
                    )}

                    <PeriodPriceValue>
                      <FormattedMessage
                        id="price"
                        values={{
                          value: pricePerMonth,
                        }}
                      />
                    </PeriodPriceValue>
                  </PeriodPrice>

                  <FormattedMessage id="chooseSubscribe.period.inMonth" />
                </PeriodBody>

                {isNotSecondTariff && (
                  <PeriodFooter style={styles?.Footer || {}}>
                    <FormattedMessage
                      id={`chooseSubscribe.period.per.${durationPeriod}`}
                      values={{
                        value: price,
                        period: monthsNumber,
                      }}
                    />
                  </PeriodFooter>
                )}

                {withDiscountPersent && note && (
                  <PeriodDiscountPersent>{note}</PeriodDiscountPersent>
                )}
              </Period>
            );
          }
        )}
      </Periods>

      {!userSubscriptions?.length ||
      (!isActiveSubscriptions && isLoadedSubscription) ? (
        <SubscribeButton
          onClick={handleOnSubscribe}
          variant={subscribeButtonVariant}
          {...(gADataAttrs || {})}
        >
          <FormattedMessage
            id="chooseSubscribe.button"
            values={{
              value: selectedPeriod.price,
              period: selectedPeriod.title,
            }}
          />

          {withDiscountPersent && selectedPeriod?.note && (
            <SubscribeButtonDiscount>
              {selectedPeriod.note}
            </SubscribeButtonDiscount>
          )}
        </SubscribeButton>
      ) : (
        <ActiveMessage>
          <FormattedMessage id="subscriptions.activeStatus" />
        </ActiveMessage>
      )}
    </>
  );
};

SubscriptionsPlans.propTypes = {
  service: PropTypes.objectOf(PropTypes.any).isRequired,
  tariffs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  userSubscriptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onChange: PropTypes.func,
  onSubscribe: PropTypes.func,
  activePeriodId: PropTypes.number,
  styles: PropTypes.objectOf(PropTypes.any),
  withDiscountPersent: PropTypes.bool,
  periodBackgrounds: PropTypes.arrayOf(PropTypes.string),
  subscribeButtonVariant: PropTypes.string,
  activeBorderColor: PropTypes.string,
  activeHeaderColor: PropTypes.string,
  isLoadedSubscription: PropTypes.bool.isRequired,
};

SubscriptionsPlans.defaultProps = {
  userSubscriptions: [],
  onChange: () => {},
  onSubscribe: () => {},
  activePeriodId: 1,
  styles: {},
  withDiscountPersent: true,
  periodBackgrounds: [],
  subscribeButtonVariant: 'default',
  activeBorderColor: null,
  activeHeaderColor: null,
};

export default memo(SubscriptionsPlans);
