import styled from 'styled-components';

import { mediaQueryMobileLargeMin } from 'styles';

const Wrapper = styled.section`
  padding: 24px 16px;
  width: 100%;
  max-width: 774px;
  position: relative;

  @media ${mediaQueryMobileLargeMin} {
    padding: 40px 24px;
    margin: 0 auto;
  }
`;

export default Wrapper;
