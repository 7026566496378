import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';

import Button from 'components/Button';
import { H1, SectionDescription } from 'components/Typography';

import {
  colorGreyLight,
  colorBlueDark,
  colorWhite,
  mediaQueryMobileLargeMin,
  mediaQueryMobileMin,
  mediaQueryDesktopMin,
  colorBlack,
} from 'styles';

const moveRed = keyframes`
  0% { transform: scale(1) translate(0%, 0%); } 
  20%{ transform: scale(1.1) translate(-40%, -10%); }
  40%{ transform: scale(0.7) translate(-30%, -10%); }
  60%{ transform: scale(1) translate(-30%, 0%); }
  80%{ transform: scale(0.6) translate(-10%, 0%); }
  100%{ transform: scale(1) translate(0%, 0%); }
`;

const moveGreen = keyframes`
  0% { transform: scale(1) translate(0%, 0%); } 
  20%{ transform: scale(0.6) translate(20%, -10%); }
  40%{ transform: scale(1) translate(30%, 10%); }
  60%{ transform: scale(0.9) translate(30%, 0%); }
  80%{ transform: scale(0.7) translate(10%, 0%); }
  100%{ transform: scale(1) translate(0%, 0%); }
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 0;
  padding: 32px 0;
  background-color: ${colorWhite};

  @media ${mediaQueryMobileLargeMin} {
    padding-top: 48px;
  }
`;

export const WrapperContent = styled.div`
  padding: 0 16px;

  @media ${mediaQueryMobileLargeMin} {
    max-width: 1146px;
    margin: 0 auto;
    position: relative;
  }
`;

export const Image = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;

  ${({ hideOnMobile, hideOnDesktop }) => {
    if (hideOnMobile || hideOnDesktop) {
      return `
        display: ${hideOnDesktop ? 'block' : 'none'};

        @media ${mediaQueryMobileLargeMin} {
          display: ${hideOnDesktop ? 'none' : 'block'};
        }
      `;
    }

    return '';
  }}
`;

export const Title = styled(H1)``;

export const Description = styled(SectionDescription)`
  @media ${mediaQueryMobileLargeMin} {
    max-width: 680px;
    margin: 0 auto 24px;
  }
`;

export const SubscribeButton = styled(Button)`
  width: 100%;
  margin-bottom: 24px;

  @media ${mediaQueryMobileMin} {
    max-width: 228px;
    margin: 0 auto 24px;
  }

  @media ${mediaQueryMobileLargeMin} {
    margin-bottom: 40px;
  }
`;

export const BgImagesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 555px;
  z-index: -1;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    ${colorWhite} -106.92%,
    ${rgba(colorGreyLight, 0.15)} -5.62%,
    ${rgba(colorGreyLight, 0.15)} -3.46%,
    ${rgba(colorBlueDark, 0.14)} 48.27%
  );
`;

export const ImageRed = styled.img`
  width: 217px;
  height: 210px;
  position: absolute;
  top: 330px;
  right: -110px;
  z-index: -1;

  @media ${mediaQueryMobileLargeMin} {
    width: 612px;
    height: 599px;
    top: 16px;
    left: 50%;
    will-change: transform;
    animation: ${moveRed} 15s infinite;
  }
`;

export const ImageGreen = styled.img`
  width: 150px;
  height: 155px;
  position: absolute;
  top: 303px;
  left: 0;
  z-index: -1;

  @media ${mediaQueryMobileLargeMin} {
    width: 480px;
    height: 429px;
    top: 70px;
    left: auto;
    right: 50%;
    will-change: transform;
    animation: ${moveGreen} 15s infinite;
  }
`;

export const ImagesWrapper = styled.div`
  position: relative;
  margin: 18px auto 0;
  min-height: 465px;

  @media ${mediaQueryMobileLargeMin} {
    margin-top: 16px;
    min-height: 343px;
  }

  @media ${mediaQueryDesktopMin} {
    min-height: 451px;
  }
`;

export const ImageWithBannersPhone = styled(Image)`
  width: 230px;
`;

export const ImageWithBannersDesktop = styled(Image)`
  width: 640px;
  height: 346.5px;

  @media ${mediaQueryDesktopMin} {
    width: 840px;
    height: 455px;
  }
`;

export const ReadUs = styled.div`
  margin-top: 24px;

  @media ${mediaQueryDesktopMin} {
    margin-top: 40px;
  }
`;

export const ReadUsTitle = styled.div`
  text-align: center;
  font: 16px/28px;
  color: ${colorBlack};
`;

export const Avatars = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  @media ${mediaQueryMobileLargeMin} {
    margin-top: 16px;
  }
`;

export const AvatarImg = styled.img`
  border-radius: 64px;
  margin: 0 -10px;

  @media ${mediaQueryMobileMin} {
    margin: 0 -5px;
  }
`;
