import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useIntersectionObserver, useSelectorProps } from 'hooks';
import {
  getTariffsList,
  getServiceById,
  makeGetSubscriptions,
  makeGetTokens,
  getUserSubscriptions,
} from 'selectors';

import { getPaymentIframe } from 'actions';

import {
  DEFAULT_ACTIVE_PERIOD,
  SUBSCRIBE_TEXT_IDS,
} from 'constants/subscriptions';

import { H2, SectionDescription } from 'components/Typography';
import SubscriptionsPlans from 'components/SubscriptionsPlans';
import { TextList, TextListItem } from 'components/Lists';

import { imgRedEllipse } from 'assets/img';

import { createGtmEvent } from 'utils';
import { GTM_EVENT_ACTIONS, GTM_EVENT_LABELS } from 'constants/index';
import {
  Wrapper,
  ImageRed,
  UnsubscribeMessage,
  subscriptionsPeriodBackgrounds,
} from './styles';

const getTokens = makeGetTokens();
const getSubscriptions = makeGetSubscriptions();

const ChooseSubscribe = () => {
  const ref = React.useRef();
  const onScreen = useIntersectionObserver(ref, { threshold: 0.5 });
  const history = useHistory();
  const dispatch = useDispatch();
  const selectorProps = useSelectorProps();

  const service = useSelector(state => getServiceById(state, { id: 1 }));
  const tariffs = useSelector(state => getTariffsList(state, { serviceId: 1 }));
  const userSubscriptions = useSelector(state => getUserSubscriptions(state));
  const { isNotAuthorized, isFetching } = useSelector(state =>
    getTokens(state, selectorProps)
  );
  const subscriptions = useSelector(state =>
    getSubscriptions(state, selectorProps)
  );

  const handleClickSubscribe = useCallback(
    data => {
      if (isNotAuthorized && !isFetching) {
        window.location.replace(
          `${process.env.APP_MINFIN_LOGIN}?ref=/${process.env.APP_ROUTER_BASENAME}/`
        );
      } else {
        dispatch(
          getPaymentIframe({
            history,
            ...data,
            ...selectorProps,
          })
        );
      }
    },
    [isNotAuthorized, isFetching, selectorProps, history]
  );

  useEffect(() => {
    if (onScreen) {
      createGtmEvent({
        eventLabel: GTM_EVENT_LABELS.tariffs,
        eventAction: GTM_EVENT_ACTIONS.blockVisibility,
      });
    }
  }, [onScreen]);

  if (!service || !tariffs?.length) {
    return null;
  }

  return (
    <Wrapper ref={ref} id="subscribe">
      <H2>
        <FormattedMessage id="chooseSubscribe.title" />
      </H2>

      <SectionDescription>
        <FormattedMessage id="chooseSubscribe.description" />
      </SectionDescription>

      <SubscriptionsPlans
        service={service}
        tariffs={tariffs}
        userSubscriptions={userSubscriptions}
        onSubscribe={handleClickSubscribe}
        activePeriodId={DEFAULT_ACTIVE_PERIOD}
        withDiscountPersent={false}
        periodBackgrounds={subscriptionsPeriodBackgrounds}
        subscribeButtonVariant="gradient"
        isLoadedSubscription={
          !subscriptions?.isFetching && subscriptions?.isLoaded
        }
      />

      <ImageRed src={imgRedEllipse} alt="red ellipse" />

      <UnsubscribeMessage>
        <FormattedMessage id="chooseSubscribe.unsubscribe" />
      </UnsubscribeMessage>

      <TextList>
        {SUBSCRIBE_TEXT_IDS.map(id => (
          <TextListItem key={id}>
            <FormattedMessage id={`chooseSubscribe.paragraph.${id}`} />
          </TextListItem>
        ))}
      </TextList>
    </Wrapper>
  );
};

export default ChooseSubscribe;
