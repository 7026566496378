import styled from 'styled-components';
import { rgba } from 'polished';

import {
  colorPrimaryDarken10,
  colorWhite,
  mediaQueryMobileLargeMin,
} from 'styles';

export const Wrapper = styled.div`
  position: relative;
  z-index: 0;
`;

export const ImageRed = styled.img`
  position: absolute;
  left: -284px;
  top: 10px;
  width: 349px;
  height: 337px;
  z-index: -1;
  opacity: 0.48;

  @media ${mediaQueryMobileLargeMin} {
    left: -215px;
    width: 500px;
    height: 488px;
    top: 10px;
  }
`;

export const UnsubscribeMessage = styled.p`
  margin-bottom: 24px;
  color: ${colorPrimaryDarken10};
  font-size: 14px;
  line-height: 24px;

  @media ${mediaQueryMobileLargeMin} {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
  }
`;

export const subscriptionsPeriodBackgrounds = [
  '',
  `linear-gradient(201.49deg, rgba(47, 148, 143, 0.29) 3.78%, ${rgba(
    colorWhite,
    0
  )} 85.88%)`,
  `linear-gradient(201.49deg, #e8e5f1 3.78%, ${rgba(colorWhite, 0)} 85.88%)`,
];
