import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { scrollToElement, getImageSrc, createGtmEvent } from 'utils';

import Switcher from 'components/Switcher';

import {
  imgRedEllipse,
  imgGreenEllipse,
  imgWithBannersPhone,
  imgWithoutBannersPhone,
  imgWithBannersDesktop,
  imgWithoutBannersDesktop,
} from 'assets/img';

import * as avatars from 'assets/avatars';

import { SWITCH_STATUSES, DEVICES } from 'constants/subscriptions';

import { GTM_EVENT_ACTIONS } from 'constants/index';
import { useGADataAttrs } from 'hooks';
import {
  Wrapper,
  WrapperContent,
  Title,
  Description,
  SubscribeButton,
  ImageRed,
  ImageGreen,
  BgImagesWrapper,
  ImagesWrapper,
  ImageWithBannersPhone,
  ReadUs,
  ReadUsTitle,
  Avatars,
  AvatarImg,
  ImageWithBannersDesktop,
} from './styles';

const ACTIVE_TAB_ID = SWITCH_STATUSES.withSubscribe;
const SWITCHER_BUTTONS = Object.keys(SWITCH_STATUSES);

const TopSection = () => {
  const [activeTabId, setActiveTabId] = useState(ACTIVE_TAB_ID);

  const handleSwitcherChange = useCallback(id => {
    setActiveTabId(id);
  }, []);

  const handleClick = () => {
    scrollToElement('subscribe');

    createGtmEvent({
      eventLabel: '',
      eventAction: GTM_EVENT_ACTIONS.activateSlider,
    });
  };

  const gADataAttrs = useGADataAttrs({
    label: '',
    action: GTM_EVENT_ACTIONS.activateSlider,
  });

  return (
    <Wrapper>
      <BgImagesWrapper />
      <WrapperContent>
        <ImageRed src={imgRedEllipse} alt="red ellipse" />
        <ImageGreen src={imgGreenEllipse} alt="green ellipse" />

        <Title>
          <FormattedMessage id="landingPage.pageTitle" />
        </Title>

        <Description>
          <FormattedMessage id="landingPage.pageDescription" />
        </Description>

        <SubscribeButton
          onClick={handleClick}
          {...(gADataAttrs || {})}
          variant="gradient"
        >
          <FormattedMessage id="landingPage.topBlock.button" />
        </SubscribeButton>

        <Switcher
          activeId={ACTIVE_TAB_ID}
          buttons={SWITCHER_BUTTONS}
          onChange={handleSwitcherChange}
        />
      </WrapperContent>

      <ImagesWrapper>
        {activeTabId === SWITCH_STATUSES.withSubscribe ? (
          <>
            <ImageWithBannersPhone
              {...getImageSrc({
                url: imgWithoutBannersPhone,
                name: activeTabId,
                device: DEVICES.mobile,
                width: 230,
              })}
              alt="with subscribe phone"
              hideOnDesktop
            />

            <picture>
              <source
                media="(max-width: 1216px)"
                srcSet={
                  getImageSrc({
                    url: imgWithoutBannersDesktop,
                    name: activeTabId,
                    device: DEVICES.desktop,
                    width: 640,
                  }).srcSet
                }
              />
              <source
                srcSet={
                  getImageSrc({
                    url: imgWithoutBannersDesktop,
                    name: activeTabId,
                    device: `${DEVICES.desktop}.large`,
                    width: 840,
                  }).srcSet
                }
              />
              <ImageWithBannersDesktop
                hideOnMobile
                src={
                  getImageSrc({
                    url: imgWithoutBannersDesktop,
                    name: activeTabId,
                    device: DEVICES.desktop,
                    width: 640,
                  }).src
                }
                alt="with subscribe devices"
              />
            </picture>
          </>
        ) : (
          <>
            <ImageWithBannersPhone
              {...getImageSrc({
                url: imgWithBannersPhone,
                name: activeTabId,
                device: DEVICES.mobile,
                width: 230,
              })}
              alt="without subscribe phone"
              hideOnDesktop
            />

            <picture>
              <source
                media="(max-width: 1216px)"
                srcSet={
                  getImageSrc({
                    url: imgWithBannersDesktop,
                    name: activeTabId,
                    device: DEVICES.desktop,
                    width: 640,
                  }).srcSet
                }
              />
              <source
                srcSet={
                  getImageSrc({
                    url: imgWithBannersDesktop,
                    name: activeTabId,
                    device: `${DEVICES.desktop}.large`,
                    width: 840,
                  }).srcSet
                }
              />
              <ImageWithBannersDesktop
                hideOnMobile
                src={
                  getImageSrc({
                    url: imgWithBannersPhone,
                    name: activeTabId,
                    device: DEVICES.desktop,
                    width: 640,
                  }).src
                }
                alt="without subscribe devices"
              />
            </picture>
          </>
        )}
      </ImagesWrapper>

      <ReadUs>
        <ReadUsTitle>
          <FormattedMessage id="readUs.title" />
        </ReadUsTitle>

        <Avatars>
          {Object.keys(avatars).map((key, index) => (
            <AvatarImg
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              src={avatars[key]}
              {...getImageSrc({
                url: avatars[key],
                name: index,
                device: DEVICES.all,
                width: 44,
              })}
              width="44"
              height="44"
              alt={`avatar ${index}`}
            />
          ))}
        </Avatars>
      </ReadUs>
    </Wrapper>
  );
};

export default TopSection;
