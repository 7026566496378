import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { SwitcherStyled, Button } from './styles';

function Switcher({ buttons, onChange, activeId }) {
  const { formatMessage } = useIntl();
  const [active, setActive] = useState(activeId);

  const handleClick = useCallback(({ target }) => {
    setActive(target.id);
    onChange(target.id);
  }, []);

  return (
    <SwitcherStyled>
      {buttons.map(button => (
        <Button
          id={button}
          key={button}
          onClick={handleClick}
          isActive={active === button}
        >
          {formatMessage({ id: `switcher.button.${button}` })}
        </Button>
      ))}
    </SwitcherStyled>
  );
}

Switcher.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  activeId: PropTypes.string,
};

Switcher.defaultProps = {
  buttons: [],
  onChange: () => {},
  activeId: null,
};

export default memo(Switcher);
