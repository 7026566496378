import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import LandingPageHeader from 'components/LandingPageHeader';
import ContentSection from 'components/ContentSection';
import { H2, SectionDescription } from 'components/Typography';
import TopSection from 'components/LandingSections/TopSection';
import GetSubscribePeriodBlock from 'components/LandingSections/GetSubscribePeriodBlock';
import WhatGiveSubscription from 'components/LandingSections/WhatGiveSubscription';
import ChooseSubscribe from 'components/LandingSections/ChooseSubscribe';

import { imgGreenEllipse } from 'assets/img';
import { useIntersectionObserver } from 'hooks';
import { GTM_EVENT_ACTIONS, GTM_EVENT_LABELS } from 'constants/index';
import { createGtmEvent } from 'utils';
import { ImageGreen, Content } from './styles';

const LandingPage = ({ loadData }) => {
  const ref = React.useRef();
  const onScreen = useIntersectionObserver(ref, { threshold: 0.5 });

  useEffect(() => {
    if (onScreen) {
      createGtmEvent({
        eventLabel: GTM_EVENT_LABELS.whyBanners,
        eventAction: GTM_EVENT_ACTIONS.blockVisibility,
      });
    }
  }, [onScreen]);

  return (
    <>
      <LandingPageHeader loadData={loadData} />
      <Content>
        <TopSection />

        <ContentSection ref={ref}>
          <H2>
            <FormattedMessage id="whyWeShowAdvertising.title" />
          </H2>

          <SectionDescription>
            <FormattedMessage id="whyWeShowAdvertising.description" />
          </SectionDescription>

          <ImageGreen src={imgGreenEllipse} alt="green ellipse" />
        </ContentSection>

        <ContentSection>
          <GetSubscribePeriodBlock />
        </ContentSection>

        <ContentSection>
          <WhatGiveSubscription />
        </ContentSection>

        <ContentSection>
          <ChooseSubscribe />
        </ContentSection>
      </Content>
    </>
  );
};

LandingPage.propTypes = {
  loadData: PropTypes.func.isRequired,
};

export default memo(LandingPage);
