import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createGtmEvent, scrollToElement } from 'utils';
import { imgHeart } from 'assets/img';

import { useIntersectionObserver } from 'hooks';
import { GTM_EVENT_ACTIONS, GTM_EVENT_LABELS } from 'constants/index';
import {
  GetSubscribePeriod,
  GetSubscribePeriodText,
  HeartImg,
  ButtonStyled,
} from './styles';

const GetSubscribePeriodBlock = () => {
  const ref = React.useRef();
  const onScreen = useIntersectionObserver(ref, { threshold: 0.5 });

  const handleClick = () => scrollToElement('subscribe');

  useEffect(() => {
    if (onScreen) {
      createGtmEvent({
        eventLabel: GTM_EVENT_LABELS.enjoyContent,
        eventAction: GTM_EVENT_ACTIONS.blockVisibility,
      });
    }
  }, [onScreen]);

  return (
    <GetSubscribePeriod ref={ref}>
      <HeartImg
        src={imgHeart}
        alt="heart"
        loading="lazy"
        width="32"
        height="32"
      />
      <GetSubscribePeriodText>
        <FormattedMessage id="getSubscribePeriod.text" />
      </GetSubscribePeriodText>
      <ButtonStyled onClick={handleClick} variant="gradient">
        <FormattedMessage id="getSubscribePeriod.button" />
      </ButtonStyled>
    </GetSubscribePeriod>
  );
};

export default GetSubscribePeriodBlock;
