import styled from 'styled-components';
import { mediaQueryMobileLargeMin } from 'styles';

export const Content = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const ImageGreen = styled.img`
  width: 208px;
  height: 215px;
  position: absolute;
  top: 0px;
  right: -112px;
  opacity: 0.5;

  @media ${mediaQueryMobileLargeMin} {
    width: 319px;
    height: 326px;
    position: absolute;
    top: -82px;
    right: -65px;
  }
`;
