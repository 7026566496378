import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
// import { useDispatch } from 'react-redux';

import { usePageLinks, useMetaPage, useSeoMeta } from 'hooks';

import PageHelmet from 'components/PageHelmet';

const PageHeader = ({ pageName, schemaScripts }) => {
  // const dispatch = useDispatch();
  const meta = useMetaPage();
  const pageLinks = usePageLinks();
  // const selectorProps = useSelectorProps();

  const {
    title,
    metaTitle,
    metaDescription: description,
    pageTitle,
  } = useSeoMeta(pageName);

  const pageScripts = useMemo(() => [...schemaScripts], [schemaScripts]);
  const helmetProps = useMemo(
    () => ({
      meta,
      title,
      description,
      metaTitle,
      metaDescription: description,
      script: pageScripts,
      link: pageLinks,
    }),
    [title, description, metaTitle, pageTitle, pageScripts, pageLinks]
  );

  // useEffect(() => {
  //   loadData(dispatch, selectorProps);
  // }, []);

  return <PageHelmet {...helmetProps} />;
};

PageHeader.propTypes = {
  pageName: PropTypes.string.isRequired,
  schemaScripts: PropTypes.arrayOf(PropTypes.any),
  // loadData: PropTypes.func.isRequired,
};

PageHeader.defaultProps = {
  schemaScripts: [],
};

export default memo(PageHeader);
