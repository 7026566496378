import styled from 'styled-components';

import { h1, h2, h3, sectionDescription, paragraph } from 'styles/variables';

export const H1 = styled.h1`
  ${h1}
`;

export const H2 = styled.h2`
  ${h2}
`;

export const H3 = styled.h3`
  ${h3}
`;

export const Paragraph = styled.p`
  ${paragraph}
`;

export const SectionDescription = styled.p`
  ${sectionDescription}
`;
