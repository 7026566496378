import styled from 'styled-components';

import {
  colorWhite,
  fontFamilyPrimary,
  colorPrimary400,
  colorCyan,
  mediaQueryMobileLargeMin,
  colorPrimary600,
} from 'styles';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font: 700 12px/16px ${fontFamilyPrimary};
  color: ${colorWhite};
  background: ${colorPrimary400};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  height: 48px;
  padding: 16px;
  border-radius: 4px;
  transition: opacity 0.3s;
  position: relative;
  z-index: 0;
  margin: 24px auto 0;
  flex-direction: column;

  &:hover {
    opacity: 0.8;
  }

  ${({ variant }) =>
    variant === 'gradient' &&
    `
    background: linear-gradient(90deg, ${colorPrimary400} 0%, ${colorCyan} 100%);
    transition: background 1s ease-out;

    &::after {
      content: '';
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(90deg, ${colorCyan} 0%, ${colorCyan} 100%);
      border-radius: 4px;
      transition: all 300ms;
      z-index: -1;
    }

    &:hover {
      opacity: 1;
    }

    &:hover::after {
      opacity: 1;
    }

    @media ${mediaQueryMobileLargeMin} {
      height: 56px;
    }
  `}

  ${({ variant, color }) =>
    variant === 'transparent' &&
    `
    background: none;
    padding: 16px 0;
    color: ${color || colorPrimary600};

    &::after {
      content: none;
    }
  `}
`;

export default Button;
