import styled from 'styled-components';

import Button from 'components/Button';

import { colorBlack800, mediaQueryMobileLargeMin } from 'styles';

export const GetSubscribePeriod = styled.div`
  padding: 16px;
  text-align: center;
  background: linear-gradient(
    92.1deg,
    rgba(191, 211, 232, 0.3776) 1.77%,
    rgba(216, 208, 230, 0.64) 98.23%
  );
  border-radius: 24px 24px 0px 24px;
  position: relative;

  @media ${mediaQueryMobileLargeMin} {
    padding: 24px 16px;
  }
`;

export const GetSubscribePeriodText = styled.div`
  color: ${colorBlack800};
  font-size: 16px;
  line-height: 28px;

  @media ${mediaQueryMobileLargeMin} {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const HeartImg = styled.img`
  position: absolute;
  top: -13px;
  left: 0;
`;

export const ButtonStyled = styled(Button)`
  @media ${mediaQueryMobileLargeMin} {
    width: 288px;
  }
`;
