import styled from 'styled-components';

import { colorBlack900, mediaQueryMobileLargeMin } from 'styles';

export const TextList = styled.ul`
  list-style-type: disc;
  padding-inline-start: 16px;
`;

export const TextListItem = styled.li`
  margin-bottom: 16px;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: ${colorBlack900};

  @media ${mediaQueryMobileLargeMin} {
    font-size: 14px;
    line-height: 24px;
  }
`;
