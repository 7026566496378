import styled from 'styled-components';
import {
  colorBlack800,
  colorWhite,
  colorBlack300,
  fontFamilySecondary,
} from 'styles';

export const SwitcherStyled = styled.div`
  width: 260px;
  height: 40px;
  background-color: ${colorWhite};
  padding: 2px;
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
`;

export const Button = styled.button`
  color: ${({ isActive }) => (isActive ? colorWhite : colorBlack800)};
  background-color: ${({ isActive }) =>
    isActive ? colorBlack300 : colorWhite};
  font: 700 12px/16px ${fontFamilySecondary};
  width: 100%;
  border-radius: 8px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;
