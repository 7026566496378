import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const PageHelmet = memo(({ title, description, meta, ...props }) => (
  <Helmet
    title={title}
    meta={[
      {
        name: 'description',
        content: description,
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:description',
        content: description,
      },
      ...meta,
    ]}
    {...props}
  />
));

PageHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

PageHelmet.defaultProps = {
  title: '',
  description: '',
  meta: [],
};

export default PageHelmet;
